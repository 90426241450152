@import "colors.scss";

body {
  font-family: 'MTSCompactRegular, TSMTSSansRegular, sans-serif, Arial';
  color: $dark-text;
  font-size: 14px;
}

h1, h2, h3, h4, h5, h6, strong {
  color: $dark-text;
  font-size: 14px;
  font-family: 'MTSWideMedium';
  font-weight: normal;
  margin: 0;
}

h1 {
  font-size: 24px;
}


p {
  color: $dark-text;
  font-size: 14px;
  margin: 0;
}

a {
  font-size: 14px;
  text-decoration: none;
  transition: 0.3s;

  &:hover {
    text-decoration: none;
  }
}

div, a, p, span, input, textarea, select, button {
  color: $dark-text;
  font-size: 14px;
  font-family: 'MTSCompactRegular';
}

.text-regular {
  font-size: 14px;
  font-family: "MTSCompactRegular" !important;
}