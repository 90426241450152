@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0% !important;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto !important;
    width: auto !important;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto !important;
    width: 100% !important;
    max-width: 100% !important;;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto !important;
    width: 50% !important;
    max-width: 50% !important;;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto !important;
    width: 33.3333333333% !important;
    max-width: 33.3333333333% !important;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto !important;
    width: 25% !important;
    max-width: 25% !important;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto !important;
    width: 20% !important;
    max-width: 20% !important;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto !important;
    width: 16.6666666667% !important;
    max-width: 16.6666666667% !important;
  }

  .col-xxl-auto {
    flex: 0 0 auto !important;
    width: auto !important;
  }

  .col-xxl-1 {
    flex: 0 0 auto !important;
    width: 8.33333333% !important;
    max-width: 8.33333333% !important;
  }

  .col-xxl-2 {
    flex: 0 0 auto !important;
    width: 16.66666667% !important;
    max-width: 16.66666667% !important;
  }

  .col-xxl-3 {
    flex: 0 0 auto !important;
    width: 25% !important;
    max-width: 25% !important;
  }

  .col-xxl-4 {
    flex: 0 0 auto !important;
    width: 33.33333333% !important;
    max-width: 33.33333333% !important;
  }

  .col-xxl-5 {
    flex: 0 0 auto !important;
    width: 41.66666667% !important;
    max-width: 41.66666667% !important;
  }

  .col-xxl-6 {
    flex: 0 0 auto !important;
    width: 50% !important;
    max-width: 50% !important;
  }

  .col-xxl-7 {
    flex: 0 0 auto !important;
    width: 58.33333333% !important;
    max-width: 58.33333333% !important;
  }

  .col-xxl-8 {
    flex: 0 0 auto !important;
    width: 66.66666667% !important;
    max-width: 66.66666667% !important;
  }

  .col-xxl-9 {
    flex: 0 0 auto !important;
    width: 75% !important;
    max-width: 75% !important;
  }

  .col-xxl-10 {
    flex: 0 0 auto !important;
    width: 83.33333333% !important;
    max-width: 83.33333333% !important;
  }

  .col-xxl-11 {
    flex: 0 0 auto !important;
    width: 91.66666667% !important;
    max-width: 91.66666667% !important;
  }

  .col-xxl-12 {
    flex: 0 0 auto !important;
    width: 100% !important;
    max-width: 100% !important;
  }

  .offset-xxl-0 {
    margin-left: 0 !important;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333% !important;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667% !important;
  }

  .offset-xxl-3 {
    margin-left: 25% !important;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333% !important;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667% !important;
  }

  .offset-xxl-6 {
    margin-left: 50% !important;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333% !important;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667% !important;
  }

  .offset-xxl-9 {
    margin-left: 75% !important;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333% !important;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667% !important;
  }

  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0 !important;
  }

  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0 !important;
  }

  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem !important;
  }

  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem !important;
  }

  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem !important;
  }

  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem !important;
  }

  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem !important;
  }

  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem !important;
  }

  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem !important;
  }

  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem !important;
  }

  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem !important;
  }

  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }
}