@import "bootstrap-grid.scss";

body {
  padding: 0;
  margin: 0;
  font-size: 14px;
  overflow-x: hidden;
}

.page-wrapper {
  max-width: 1440px;
}

.mb-6 {
  margin-bottom: 3.75rem;
}

.flex-start {
  justify-content: flex-start;
}

.align-start {
  align-items: flex-start;
}

.align-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}