/* ==================== Icons ====================== */
.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &:before {
    content: '';
    width: 40px;
    height: 40px;
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.icon-close {
  box-sizing: content-box;
  &:before {
    background-image: url('#{$VUE_APP_BASE}/assets/icons/close.svg');
  }
  &:hover {
    &:before {
      background-image: url('#{$VUE_APP_BASE}/assets/icons/close-hover.svg');
    }
  }
  &.active {
    &:before {
      background-image: url('#{$VUE_APP_BASE}/assets/icons/close-hover.svg');
    }
  }
}

.icon-close-white {
  box-sizing: content-box;
  &:before {
    background-image: url('#{$VUE_APP_BASE}/assets/icons/close-white.svg');
  }
  &:hover {
    &:before {
      background-image: url('#{$VUE_APP_BASE}/assets/icons/close-hover.svg');
    }
  }
  &.active {
    &:before {
      background-image: url('#{$VUE_APP_BASE}/assets/icons/close-hover.svg');
    }
  }
}

.icon-exit {
  box-sizing: content-box;
  &:before {
    background-image: url('#{$VUE_APP_BASE}/assets/icons/exit.svg');
  }
  &:hover {
    &:before {
      background-image: url('#{$VUE_APP_BASE}/assets/icons/exit-hover.svg');
    }
  }
  &.active {
    &:before {
      background-image: url('#{$VUE_APP_BASE}/assets/icons/exit-hover.svg');
    }
  }
}

.icon-map {
  box-sizing: content-box;
  &:before {
    background-image: url('#{$VUE_APP_BASE}/assets/icons/icon-map.svg');
  }
}

.icon-people {
  box-sizing: content-box;
  &:before {
    background-image: url('#{$VUE_APP_BASE}/assets/icons/people.svg');
  }
}

.icon-people-tourist {
  box-sizing: content-box;
  &:before {
    background-image: url('#{$VUE_APP_BASE}/assets/icons/people-tourists.svg');
  }
}

.icon-points {
  box-sizing: content-box;
  &:before {
    background-image: url('#{$VUE_APP_BASE}/assets/icons/points.svg');
  }
}

.icon-point-stains {
  box-sizing: content-box;
  &:before {
    background-image: url('#{$VUE_APP_BASE}/assets/icons/point-stains.svg');
  }
}

.icon-hexagon {
  box-sizing: content-box;
  &:before {
    background-image: url('#{$VUE_APP_BASE}/assets/icons/hexagon.svg');
  }
}

.icon-money {
  box-sizing: content-box;
  &:before {
    background-image: url('#{$VUE_APP_BASE}/assets/icons/money.svg');
  }
}

.icon-scales {
  box-sizing: content-box;
  &:before {
    background-image: url('#{$VUE_APP_BASE}/assets/icons/scales.svg');
  }
}

.icon-trend-line {
  box-sizing: content-box;
  &:before {
    background-image: url('#{$VUE_APP_BASE}/assets/icons/trend-line.svg');
  }
}

.icon-cellular-signal {
  box-sizing: content-box;
  &:before {
    background-image: url('#{$VUE_APP_BASE}/assets/icons/cellular-signal.svg');
  }
}

.icon-union {
  box-sizing: content-box;
  &:before {
    background-image: url('#{$VUE_APP_BASE}/assets/icons/union.svg');
  }
}

.icon-profit {
  box-sizing: content-box;
  &:before {
    background-image: url('#{$VUE_APP_BASE}/assets/icons/profit.svg');
  }
}

.icon-vector-up {
  box-sizing: content-box;
  &:before {
    background-image: url('#{$VUE_APP_BASE}/assets/icons/vector-up.svg');
  }
}

.icon-look-a-like {
  box-sizing: content-box;
  &:before {
    background-image: url('#{$VUE_APP_BASE}/assets/icons/look-a-like.svg');
  }
}

.icon-balance {
  box-sizing: content-box;
  &:before {
    background-image: url('#{$VUE_APP_BASE}/assets/icons/balance.svg');
  }
}

.icon-search {
  box-sizing: content-box;
  &:before {
    background-image: url('#{$VUE_APP_BASE}/assets/icons/search.svg');
  }
}

.icon-play {
  &:before {
    background-image: url('#{$VUE_APP_BASE}/assets/icons/play.svg');
  }
}

.icon-pause {
  &:before {
    background-image: url('#{$VUE_APP_BASE}/assets/icons/paused.svg');
  }
}