$mtsFonts: '#{$VUE_APP_BASE}/assets/fonts';
$mtsFontsPath: $mtsFonts + '/MTSSans2022/';

/* ================ Compact Path ================ */
$mtsCompactRegularPath: $mtsFontsPath + 'Compact/Regular';
$mtsCompactMediumPath: $mtsFontsPath + 'Compact/Medium';
$mtsCompactBlackPath: $mtsFontsPath + 'Compact/Black';
$mtsCompactBoldPath: $mtsFontsPath + 'Compact/Bold';

/* ================ Extended Path ================ */
$mtsExtendedRegularPath: $mtsFontsPath + 'Extended/Regular';
$mtsExtendedMediumPath: $mtsFontsPath + 'Extended/Medium';
$mtsExtendedBlackPath: $mtsFontsPath + 'Extended/Black';
$mtsExtendedBoldPath: $mtsFontsPath + 'Extended/Bold';

/* ================ Text Path ================ */
$mtsTextRegularPath: $mtsFontsPath + 'Text/Regular';
$mtsTextMediumPath: $mtsFontsPath + 'Text/Medium';
$mtsTextBlackPath: $mtsFontsPath + 'Text/Black';
$mtsTextBoldPath: $mtsFontsPath + 'Text/Bold';

/* ================ UltraExtended Path ================ */
$mtsUltraExtendedRegularPath: $mtsFontsPath + 'UltraExtended/Regular';
$mtsUltraExtendedMediumPath: $mtsFontsPath + 'UltraExtended/Medium';
$mtsUltraExtendedBlackPath: $mtsFontsPath + 'UltraExtended/Black';
$mtsUltraExtendedBoldPath: $mtsFontsPath + 'UltraExtended/Bold';

/* ================ Wide Path ================ */
$mtsWideRegularPath: $mtsFontsPath + 'Wide/Regular';
$mtsWideMediumPath: $mtsFontsPath + 'Wide/Medium';
$mtsWideBlackPath: $mtsFontsPath + 'Wide/Black';
$mtsWideBoldPath: $mtsFontsPath + 'Wide/Bold';

@font-face {
  font-family: 'MTSCompactRegular';
  src: url($mtsCompactRegularPath + '/MTSCompact-Regular.eot');
  src:
          url($mtsCompactRegularPath + '/MTSCompact-Regular.eot?#iefix') format('embedded-opentype'),
          url($mtsCompactRegularPath + '/MTSCompact-Regular.woff2') format('woff2'),
          url($mtsCompactRegularPath + '/MTSCompact-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MTSWideRegular';
  src: url($mtsWideRegularPath + '/MTSCompact-Regular.eot');
  src:
          url($mtsWideRegularPath + '/MTSWide-Regular.eot?#iefix') format('embedded-opentype'),
          url($mtsWideRegularPath + '/MTSWide-Regular.woff2') format('woff2'),
          url($mtsWideRegularPath + '/MTSWide-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MTSCompactMedium';
  src: url($mtsCompactMediumPath + '/MTSCompact-Medium.eot');
  src:
          url($mtsCompactMediumPath + '/MTSCompact-Medium.eot?#iefix') format('embedded-opentype'),
          url($mtsCompactMediumPath + '/MTSCompact-Medium.woff2') format('woff2'),
          url($mtsCompactMediumPath + '/MTSCompact-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MTSExtendedBlack';
  src: url($mtsExtendedBlackPath + '/MTSExtended-Black.eot');
  src:
          url($mtsExtendedBlackPath + '/MTSExtended-Black.eot?#iefix') format('embedded-opentype'),
          url($mtsExtendedBlackPath + '/MTSExtended-Black.woff2') format('woff2'),
          url($mtsExtendedBlackPath + '/MTSExtended-Black.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MTSWideMedium';
  src: url($mtsWideMediumPath + '/MTSWide-Medium.eot');
  src:
          url($mtsWideMediumPath + '/MTSWide-Medium.eot?#iefix') format('embedded-opentype'),
          url($mtsWideMediumPath + '/MTSWide-Medium.woff2') format('woff2'),
          url($mtsWideMediumPath + '/MTSWide-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MTSWideBold';
  src: url($mtsWideBoldPath + '/MTSWide-Bold.eot');
  src:
          url($mtsWideBoldPath + '/MTSWide-Bold.eot?#iefix') format('embedded-opentype'),
          url($mtsWideBoldPath + '/MTSWide-Bold.woff2') format('woff2'),
          url($mtsWideBoldPath + '/MTSWide-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MTSTextRegular';
  src: url($mtsTextRegularPath + '/MTSText-Regular.eot');
  src:
          url($mtsTextRegularPath + '/MTSText-Regular.eot?#iefix') format('embedded-opentype'),
          url($mtsTextRegularPath + '/MTSText-Regular.woff2') format('woff2'),
          url($mtsTextRegularPath + '/MTSText-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: "MTSSansRegular";
  src: url($mtsFonts + "/MTSSans-Regular.otf") format("eot"),
  url($mtsFonts + "/MTSSans-Regular.otf?#iefix") format('embedded-opentype'),
  url($mtsFonts + "/MTSSans-Regular.woff") format('woff'),
  url($mtsFonts + "/MTSSans-Regular.ttf") format('ttf');
}

@font-face {
  font-family: "MTSSansMedium";
  src: url($mtsFonts + "/MTSSans-Medium.otf") format("eot"),
  url($mtsFonts + "/MTSSans-Medium.otf?#iefix") format('embedded-opentype'),
  url($mtsFonts + "/MTSSans-Medium.woff") format('woff'),
  url($mtsFonts + "/MTSSans-Medium.ttf") format('ttf');
}

@font-face {
  font-family: "MTSSansBlack";
  src: url($mtsFonts + "/MTSSans-Black.otf") format("eot"),
  url($mtsFonts + "/MTSSans-Black.otf?#iefix") format('embedded-opentype'),
  url($mtsFonts + "/MTSSans-Black.woff") format('woff'),
  url($mtsFonts + "/MTSSans-Black.ttf") format('ttf');
}

@font-face {
  font-family: "MTSSansBold";
  src: url($mtsFonts + "/MTSSans-Bold.otf") format("eot"),
  url($mtsFonts + "/MTSSans-Bold.otf?#iefix") format('embedded-opentype'),
  url($mtsFonts + "/MTSSans-Bold.woff") format('woff'),
  url($mtsFonts + "/MTSSans-Bold.ttf") format('ttf');
}

@font-face {
  font-family: "MTSSansUltraWide";
  src: url($mtsFonts + "/MTSSans-UltraWide.otf") format("eot"),
  url($mtsFonts + "/MTSSans-UltraWide.otf?#iefix") format('embedded-opentype'),
  url($mtsFonts + "/MTSSans-UltraWide.woff") format('woff'),
  url($mtsFonts + "/MTSSans-UltraWide.ttf") format('ttf');
}